body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', */
    /* 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', */
    /* sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

th {
  text-align: left;
}

.table > .card-table-row { 
  width: 100%;
  background: white;
  margin-top: .7em;
}

.table{
  width: 100%;
}

.card-table-row:hover{
  background: #fff !important;
} 


@media (max-width: 1024px) {
  .product-title{
    text-overflow: ellipsis;
  }

  .table-container {
    overflow-x: auto;
  }

  .table { 
    /* width: max-content; */
    width: calc(100% + 20rem);
  }

  .table-webhook { 
    /* width: max-content; */
    width: calc(100% + 34rem);
  }

  .rs-sidenav-collapse-out{
    display: none;
    transition: width ease .3s, top ease .3s .3s, transform ease .3s;

  }

  .rs-sidenav-collapse-in{
    position: fixed;
    height: 100%;
    z-index: 999;
    display: block;
    width: 55% !important;
    transition: width ease .3s, top ease .3s .3s, transform ease .3s;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.skeleton-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #DDDBDD;
}
.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
  -webkit-animation: shimmer 2s infinite;
          animation: shimmer 2s infinite;
  content: "";
}
@-webkit-keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}



.grey-filter{
  filter: grayscale(1);
}

.blue-filter{
  filter: blur(1px);
}

@import url('default-theme.css');

.rs-steps-item-icon-wrapper{
  border-color: var(--primary-color)ed !important;
  background: var(--primary-color) !important;
  color: var(--light-color);
}

.rs-steps-item-status-finish
  .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon)
  .rs-steps-item-icon::before {
    color: var(--light-color);
}

.rs-steps-item-status-finish 
.rs-steps-item-tail, 
.rs-steps-item-status-finish .rs-steps-item-title::after {
  border-color: var(--primary-color);
}
