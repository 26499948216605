.course-cover {
    /* background-image: url('../../assets/img/people-2557396_1920.jpg'); */
    /* background-size: cover;
    width: 100vw;
    height: 450px; */
}

.container {
    position: relative;
    width: 100%;
    height: 50vh;
    /* background-image: url('../../assets/img/people-2557396_1920.jpg'); Adicione o caminho para a sua imagem de fundo */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.overlay {
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 60%; /* Ajuste a altura conforme necessário */
    background-image: linear-gradient(0deg,var(--background) 5%,transparent)
}

.cover-content{
    width: 100%;
    bottom: 15%;
    /* left: 5%; */
    padding: 0 5%;
    position: absolute;
}

.course-cta-button{
    width: auto;
    height: 40px;
}

.course-metadata{
    font-size: 0.8rem;
    color: #ffffffab;
}



.ledContainer {
	/* position: absolute; */
	/* top: 50%; */
	/* left: 50%; */
	/* transform: translate(-50%, -50%); */
	color: #fff;
	/* padding: 1.5rem 3rem; */
	display: flex;
    align-items: center;
	width: 380px;
	justify-content: flex-start;
}

.recordLed {
	width: 10px;
	height: 10px;
	background-color: red;
	border-radius: 50%;
}

.liveText {
	font-size: 12px;
}

.live {
    animation: blinking 1s cubic-bezier(.5, 0, 1, 1) infinite alternate;  
}

@keyframes blinking {  
    from { opacity: 1; }
    to { opacity: 0; }
}


.custom-timeline {
    margin-left: 20px;
}

.custom-timeline .rs-timeline-item-custom-dot .rs-icon {
    position: absolute;
    background: rgb(87, 87, 87);
    top: 0;
    left: -2px;
    border: 2px solid rgb(87, 87, 87);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 18px;
    padding-top: 9px;
    color: #999;
    margin-left: -13px;
}

.custom-timeline .rs-timeline-item-content {
    margin-left: 24px;
}

.rs-timeline-item-tail{
    background: rgb(87, 87, 87) !important;
}