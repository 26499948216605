@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
:root {
    --primary-color: red;
    --light-color: white;

    --primary-color-hover: rgb(207, 3, 3);
    --primary-color-hover-extra: rgb(189, 9, 9);
    --light-color-hover: white;


    --background: rgb(18, 18, 20);
    --font: #ffffff;
    --font-dark: #141414;
    --background-layer2: #28282c;


    --colors-grey-100: #E1E1E6;
    --colors-grey-200: #C4C4CC;
    --colors-grey-300: #8D8D99;
    --colors-grey-400: #7C7C8A;
    --colors-grey-500: #505059;
    --colors-grey-600: #323238;
    --colors-grey-700: #29292E;
    --colors-grey-800: #202024;
    --colors-grey-900: #121214;
    --colors-grey-950: #09090A;
    --colors-text-title: #E1E1E6;
    --colors-text-base: #C4C4CC;
    --colors-shape-primary: #202024;

}

body {
    background: rgb(18, 18, 20);
    background: var(--background);
    font-family: 'Montserrat', sans-serif !important;
}

p {
    color: white;
    color: var(--light-color)
}
span.text-light {
    color: white;
    color: var(--light-color)
}


h1, h2, h3, h4, h5, h6 {
    color: white;
    color: var(--light-color);
}

.subtitle{
    color: #ffffffab !important;
}

.rs-modal-content{
    background-color: #28282c;
    background-color: var(--background-layer2);
}

.rs-nav-item > .rs-nav-item-content {
    color: #ffffff !important;
    color: var(--font) !important;
}

.rs-nav-item > .rs-nav-item-content:hover {
    background: unset !important;
}


.rs-sidenav-inverse{
    background-color: rgb(18, 18, 20) !important;
    background-color: var(--background) !important;
}

.rs-nav-default {
    background-color: #28282c !important;
    background-color: var(--background-layer2) !important;
    border-bottom: 1px solid rgb(18, 18, 20);
}

.rs-nav-item-content{
    font-weight: 600;
}

.rs-dropdown-item-content > a {
    color: #141414;
    color: var(--font-dark);
}

.menu-container{
    margin-left: 20px;
}

.video-card-tag{
    background-color: #323238;
    background-color: var(--colors-grey-600);
    color: white;
    color: var(--light-color);
    text-transform: uppercase;
    font-size: .6rem;
    font-weight: 800;
    cursor: pointer;
    transition: background 0.2s ease 0s, color 0.2s ease 0s;
}
.video-card-tag:hover{
    background-color: #505059;
    background-color: var(--colors-grey-500);
}


.swiper-button-next::after, .swiper-button-prev::after {
    /* content: none !important; */
}

.swiper-button-next,
.swiper-button-prev {
    color: white;
    color: var(--light-color); /* Altere para a cor que desejar */
    top: 50%;
    width: 27px;
    height: 44px;
    margin-top: -22px;
}

.video-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-left: 50px;
    padding-right: 10px;
    width: 100%;
}

.video-thumb-card {
    /* padding: 10px; */
    flex: 1 1;
    /* max-width: 300px; */
    box-sizing: border-box;
}

@media (min-width: 768px) {
    .video-thumb-card {
        /* padding: 20px; */
    }
}

.mega-slider{
    height: 100%;
}

.button-small-text{
    font-size: 0.6rem;;
}

.like-button{
    color: #ffffffab;
    border-color: #ffffffab;
}

.like-button:hover,
.like-button:not(.rs-btn-disabled):hover,
.like-button:focus,
.like-button:active,
.like-button:hover:active {
    background: #ffffff;
    color: rgb(18, 18, 20);
    color: var(--background);
    border-color: #ffffff;
}

.rs-dropdown-item-panel > p {
    color: black !important;
}

.card-modules {
    background: #29292E;
    background: var(--colors-grey-700);    
    border-radius: 5px;
}

.breadcrumb-text{
    color: #7C7C8A;
    color: var(--colors-grey-400);
}

.lesson-selected {
    background: rgb(189, 9, 9);
    background: var(--primary-color-hover-extra);
}

.theme-table{
    color: white;
    font-weight: bold;
}

.plyr__control--overlaid{
    background: red !important;
    background: var(--primary-color) !important;
}

.plyr__control:hover {
    background: rgb(207, 3, 3) !important;
    background: var(--primary-color-hover) !important;

}

.plyr__control[aria-expanded=true] {
    background: rgb(207, 3, 3) !important;
    background: var(--primary-color-hover) !important;

}

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]:before{
    background-color: red !important;
    background-color: var(--primary-color) !important;
}

.table > .card-table-row{
    background-color: #28282c;
    background-color: var(--background-layer2);
    color: #ffffff;
    color: var(--font);
}
.table > .card-table-row:hover{
    background-color: #28282c !important;
    background-color: var(--background-layer2) !important;
    color: #ffffff;
    color: var(--font)
}

.module-item{
    background: #29292E;
    background: var(--colors-grey-700);
}

.rs-panel-heading{
    color: #ffffffab !important;
    font-weight: bold;
    font-size: 0.87rem;
}

.play-button-circle{
    color: white;
    color: var(--light-color);
    cursor: pointer;
    text-shadow: 3px 3px 20px rgb(0 0 0 / 51%);
}

.thumbnail-preview-mini{
    background-color: #C4C4CC;
    background-color: var(--colors-grey-200);
}

.progress-bar-theme{
    background-color: red;
    background-color: var(--primary-color);
}

.thumbnail-preview-mini > svg {
    color: rgb(97, 97, 97, 0.8) !important;
}

.custom-timeline .rs-timeline-item-custom-dot .rs-icon, not(.timeline-checked-dot) {
    background: #29292E !important;
    background: var(--colors-grey-700) !important;
    border-color: #29292E !important;
    border-color: var(--colors-grey-700) !important;
}

.timeline-checked-dot {
    background-color: #15b215 !important;
    border-color: #15b215 !important;
}

.rs-message-container{
    background-color: #28282c;
    background-color: var(--background-layer2);
    color: white;
    font-weight: 500;
}
.rs-message-icon-wrapper > .rs-icon-info {
    color: white !important;
}

.rs-btn-primary {
    /* stylelint-disable-next-line */
    color: #ffffff;
    background-color: red;
    background-color: var(--primary-color);
}
.rs-btn-primary.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: red;
    background: var(--primary-color);
}
.rs-btn-primary.rs-btn-loading:hover,
.rs-btn-primary.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-primary.rs-btn-loading:focus,
.rs-btn-primary.rs-btn-loading:active,
.rs-btn-primary.rs-btn-loading:hover:active {
    color: white;
    color: var(--light-color-hover);
    background-color: rgb(207, 3, 3);
    background-color: var(--primary-color-hover);
}
.rs-btn-primary:focus,
.rs-btn-primary.rs-btn-focus {
    color: #ffffff;
    background-color: rgb(207, 3, 3);
    background-color: var(--primary-color-hover);
}
.rs-btn-primary:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: rgb(207, 3, 3);
    background: var(--primary-color-hover);
}
.rs-btn-primary:hover {
    color: white;
    color: var(--light-color-hover);
}
.rs-btn-primary:not(.rs-btn-disabled):hover {
    background-color: rgb(207, 3, 3);
    background-color: var(--primary-color-hover);
}
.rs-btn-primary:not(.rs-btn-disabled):hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: rgb(207, 3, 3);
    background: var(--primary-color-hover);
}
.rs-btn-primary:active,
.rs-btn-primary.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-btn-primary {
    color: #ffffff;
    background-color: rgb(207, 3, 3);
    background-color: var(--primary-color-hover);
}
.rs-btn-primary:active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: rgb(207, 3, 3);
    background: var(--primary-color-hover);
}
.rs-btn-primary:active:hover,
.rs-btn-primary.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-primary:hover,
.rs-btn-primary:active:focus,
.rs-btn-primary.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-primary:focus,
.rs-btn-primary:active.focus,
.rs-btn-primary.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.focus {
    color: white;
    color: var(--light-color-hover);
    background-color: rgb(207, 3, 3);
    background-color: var(--primary-color-hover);
}
.rs-btn-primary:active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary:active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary:active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: rgb(189, 9, 9);
    background: var(--primary-color-hover-extra);
}
.rs-btn-primary:active,
.rs-btn-primary.rs-btn-active,
.open > .rs-dropdown-toggle.rs-btn-primary {
    background-image: none;
}
.rs-btn-primary.rs-btn-disabled:hover,
.rs-btn-primary.rs-btn-disabled:focus,
.rs-btn-primary.rs-btn-disabled.focus {
    background: rgb(189, 9, 9);
    background: var(--primary-color-hover-extra);
}
.rs-btn-primary.rs-btn-loading:hover,
.rs-btn-primary.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-primary.rs-btn-loading:focus,
.rs-btn-primary.rs-btn-loading:active,
.rs-btn-primary.rs-btn-loading:hover:active {
    color: white;
    color: var(--light-color-hover);
    background-color: rgb(207, 3, 3);
    background-color: var(--primary-color-hover);
}

.rs-btn-primary,
.rs-btn-primary > .rs-icon {
    transition: color 0.2s linear, background-color 0.3s linear;
}

.rs-tag-red {
    background-color: #f443366b !important;
}
.rs-tag-orange {
    background-color: #ff98006b !important;
}
.rs-tag-yellow {
    background-color: #ffca286b !important;
}
.rs-tag-green {
    background-color: #7cff816b !important;
    }
.rs-tag-cyan {
    background-color: #00bcd46b !important;
}
.rs-tag-blue {
    background-color: #2196f36b !important;
}
.rs-tag-violet {
    background-color: #673ab76b !important;
}

.profile-avatar { 
    background: rgb(189, 9, 9) !important; 
    background: var(--primary-color-hover-extra) !important;
}

.watch-button {
    background-color: red !important;
    background-color: var(--primary-color) !important;
}

.watch-button{
    background-color: red;
    background-color: var(--primary-color);
}

.rs-icon{
    color: red;
    color: var(--primary-color);
}



/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio:0) {
    input[type='range'] {
        overflow: hidden;
        -webkit-appearance: none;
        border-radius: 15px;
        background-color: red;
        background-color: var(--primary-color);
    }
    
    input[type='range']::-webkit-slider-runnable-track {
        height: 10px;
        /* -webkit-appearance: none; */
        color: red;
        color: var(--primary-color);
        margin-top: -1px;
    }
    
    input[type='range']::-webkit-slider-thumb {
        width: 10px;
        -webkit-appearance: none;
        height: 10px;
        cursor: ew-resize;
        background: rgb(189, 9, 9);
        background: var(--primary-color-hover-extra);
        box-shadow: -80px 0 0 80px red;
        box-shadow: -80px 0 0 80px var(--primary-color);
    }

}
/** FF*/
input[type="range"]::-moz-range-progress {
    background-color: red;
    background-color: var(--primary-color); 
}
input[type="range"]::-moz-range-track {  
    background-color: red;  
    background-color: var(--primary-color);
}
/* IE*/
input[type="range"]::-ms-fill-lower {
    background-color: red;
    background-color: var(--primary-color); 
}
input[type="range"]::-ms-fill-upper {  
    background-color: red;  
    background-color: var(--primary-color);
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', */
    /* 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', */
    /* sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

th {
  text-align: left;
}

.table > .card-table-row { 
  width: 100%;
  background: white;
  margin-top: .7em;
}

.table{
  width: 100%;
}

.card-table-row:hover{
  background: #fff !important;
} 


@media (max-width: 1024px) {
  .product-title{
    text-overflow: ellipsis;
  }

  .table-container {
    overflow-x: auto;
  }

  .table { 
    /* width: max-content; */
    width: calc(100% + 20rem);
  }

  .table-webhook { 
    /* width: max-content; */
    width: calc(100% + 34rem);
  }

  .rs-sidenav-collapse-out{
    display: none;
    transition: width ease .3s, top ease .3s .3s, -webkit-transform ease .3s;
    transition: width ease .3s, top ease .3s .3s, transform ease .3s;
    transition: width ease .3s, top ease .3s .3s, transform ease .3s, -webkit-transform ease .3s;

  }

  .rs-sidenav-collapse-in{
    position: fixed;
    height: 100%;
    z-index: 999;
    display: block;
    width: 55% !important;
    transition: width ease .3s, top ease .3s .3s, -webkit-transform ease .3s;
    transition: width ease .3s, top ease .3s .3s, transform ease .3s;
    transition: width ease .3s, top ease .3s .3s, transform ease .3s, -webkit-transform ease .3s;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.skeleton-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #DDDBDD;
}
.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
  -webkit-animation: shimmer 2s infinite;
          animation: shimmer 2s infinite;
  content: "";
}
@-webkit-keyframes shimmer {
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}
@keyframes shimmer {
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}



.grey-filter{
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
}

.blue-filter{
  -webkit-filter: blur(1px);
          filter: blur(1px);
}

.rs-steps-item-icon-wrapper{
  border-color: var(--primary-color)ed !important;
  background: var(--primary-color) !important;
  color: var(--light-color);
}

.rs-steps-item-status-finish
  .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon)
  .rs-steps-item-icon::before {
    color: var(--light-color);
}

.rs-steps-item-status-finish 
.rs-steps-item-tail, 
.rs-steps-item-status-finish .rs-steps-item-title::after {
  border-color: var(--primary-color);
}

/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap'); */

body {
  overflow-y: scroll;
  overflow-x: hidden !important;
}

.rs-container {
  min-height: 100vh;
}

a, a:visited, a:active, a:hover {
  text-decoration: none !important;
}

.rs-dropdown-menu-collapse-in > a > .rs-dropdown-item > .rs-dropdown-item-content,
.rs-dropdown-menu-collapse-in > a > .rs-dropdown-item > .rs-dropdown-item-content:hover,
.rs-dropdown-menu-collapse-in > a > .rs-dropdown-item > .rs-dropdown-item-content:active,
.rs-dropdown-menu-collapse-in > a > .rs-dropdown-item > .rs-dropdown-item-content:visited {
  color: white !important;
} 

.rs-tag {
  border-radius: 100px !important;
}

.rs-footer {
    margin-bottom: 0 !important;
}

.badge-success > .rs-badge-content {   
  background-color: #4caf50;
  top: -5px !important;
  /* left: 0 !important; */
}

.table-head{
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #d2d6dc;

    text-indent: 0;

    text-indent: initial;
    border-spacing: 2px;
    border-bottom-width: 1px;

    border-color: rgba(229, 231, 235, 1);
}

.table-title{
  letter-spacing: 0.05em;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: rgba(107, 114, 128, 1);
}

.input-clipboard {
  line-height: 1.25rem;
  font-size: 0.875rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  min-width: 12rem;
}

.table-row {
  display: flex;
  align-items: center;
}

.table-row > div {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.upsellAcceptBtn {
  padding:12px 16px;cursor:pointer;color:#FFFFFF;font-weight:600;border-radius:4px;border:1px solid;font-size:20px;
}
.upsellRejectBtn {
  margin-top:1rem;cursor:pointer;font-size:16px;text-decoration:underline;font-family:sans-serif;
}

:-webkit-autofill, :-webkit-autofill:active, :-webkit-autofill:focus, :-webkit-autofill:hover {
  -webkit-transition: all 5000s ease-in-out 0s;
  transition: all 5000s ease-in-out 0s;
  -webkit-transition-property: background-color,color;
  transition-property: background-color,color;
}

* { 
  /* font-family: 'Poppins', sans-serif  */
  /* font-family: 'Roboto', sans-serif; */
}

.clipboardButton:active{
  border-color: rgb(56, 161, 105) !important;
  color: rgb(56, 161, 105) !important;
}

.rs-modal-wrapper{
  margin-top: 5%;
}

.rs-modal-body{
  overflow-x: hidden !important;
}

.orderBump-border {
  border-color: rgb(160, 174, 192);
  color: rgb(113, 128, 159);
  border-style: dashed;
  border-radius: 0.25rem;
  border-width: 4px;

  min-height: 25px;
  /* padding: 5px; */
}

.orderBump-header {
  font-size: 18px;
  color: rgb(49, 130, 206);
  background-color: rgb(226, 232, 240);

  font-weight: 700;
  text-align: center;
  text-transform: uppercase;

  padding: 0.5rem;

  width: 100%;
  min-height: 30px;
}

.orderBump-body{
  box-sizing: border-box;
  background: rgba(244, 245, 247, 1);

  display: flex;
  flex-direction: row;
  align-items: center;
}

.orderBump-arrow{
  margin-left: 10px;
}

.orderBump-offer{
  background: white;
  padding-left: 5px;
  margin-left: 5px;
  margin-right: 10px;
  width: 100%;
  min-height: 70px;
  margin-top: 25px;
  margin-bottom: 25px;

  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -2px rgb(0 0 0 / 5%);

  display: flex;
  align-items: center;
}

.orderBump-offer {
  font-size: 19px;
  font-weight: 700;
}



.loading-payment {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10000;

  display: flex;
  justify-content: center;
  align-items: center;  overflow-x: hideen !important;
}


.page-row {
  height: auto;
}

.szh-menu__item {
  color: black !important;
}

.card-saved-container {
  border: 1px solid rgba(0, 0, 0, 0.137);
  padding: 1.2rem;
  outline: none;
  max-width: 70%;
  min-width: unset;
  border-radius: 5px;
  background-color: rgba(221, 228, 236, 0.301) !important;
  border-color: rgba(221, 228, 236, 0.459) !important;
  /* margin: 2vh 0; */
  margin-top: 10px !important;
  overflow: hidden;
}

.credit-card-last4 {
  font-family: "PT Mono", Helvetica, sans-serif;
  font-size: 18px;
}

.credit-card-last4:before {
  content: "**** **** **** ";
  color: #4f4d4d;
  font-size: 18px;

  position: -webkit-sticky;

  position: sticky;
  top: 35px;
}


.nav-logo-container{
  margin-left: 25px;
}

.thumbnail-preview {
  /* width: calc(110vw / 5); */
  height: calc(100vh / 5);
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  background-color: #f5f5f5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  display: flex;
  justify-content: center;
  align-items: center;
}

.thumbnail-preview-mini {
  cursor: pointer;
  width: 126px;
  height: 68px;
  /* border-radius: 5px; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  display: flex;
  justify-content: center;
  align-items: center;
}

.timeline-row{
  display: flex;
  flex-direction: row;
}

.timeline-preview{
  margin-right: 15px;
}


.rs-sidenav-body{
  position: relative;
  margin-top: 25px;
}

.blue-panel{
  background-color: #3498ff;
  height: 100vh;
  float: left !important;
}

.white-panel{
  float: right !important;
}

.nav-dashboard-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}

.flex-row-v-end{
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.bigCard{
  /* min-height: 350px; */
  /* max-height: 400px; */
  height: 325px;
}

.bigCard > .rs-panel-body {
  height: 100%;
}

.notificationCard:hover{
  background-color: rgb(245, 248, 255);
}
.notificationBody{
  font-size: .9em;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.4);
}

.userAvatarContainer{
  margin-right: 1% !important;
}

@media (max-height: 900px) {
  .blue-panel{
    height: 160vh;
  }

  .content {
    padding: 0px !important;
  }
  
}
@media (min-width: 2300px) {
  .svg-model{
    width: 45%;
  }
  
}

.logo-container {
  text-align: left;
  margin: 2em 2em 2em 10em;
}

@media (min-height: 900px) {
  .login-container{
    /* padding-top: 10em; */
  }
}

/* .login-container{
  margin: 2em 12em 3em 8em;
  width: 60%;
  min-height: unset !important;
} */

/* .register-container{
  margin: 0em 12em 3em 8em;
  width: 60%;
  top: 30%;
} */

.align-end-v-middle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.align-center-v-middle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-row-v-middle {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-row-v-middle-between {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.flex-column-v-middle {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.dot {
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  width: 10px;
}

.text-white-center{
  color: white;
  text-align: center;
}

.title {
  font-weight: 500 !important;
  font-size: 1.9rem;
  margin-bottom: .6em;
}


.label{
  font-size: 1rem;
}

.label-senha{
  display: flex;
  justify-content: space-between;
}

.menu-icon-open{
  color: #3498ff;
}

.btn-dark,
.btn-dark:hover,
.btn-dark:not(.rs-btn-disabled):hover,
.btn-dark:focus,
.btn-dark:active,
.btn-dark:hover:active {

  color: #fff !important;
  background-color: #464646 !important;
}

.dashboard-container{
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

@media (min-height: 880px){
  .dashboard-container{
    min-height: 100vh;
  }
}

.sidenav-container{
  /* position: fixed; */
  background-color: #3498ff;
  height: auto;
}

.welcome{
  font-size: 1.8em;
  width: 100%;
}

.holder{
  margin-top: unset !important;
  width: 100%;
  font-size: .9em;
  font-weight: 300;
}

.cardTitle{
  font-weight: 500;
  color: rgba(0,0,0,0.5);
}

.iconCinza {
  color: rgba(0,0,0,0.5);
}

.content{
  margin: auto;
  /* padding: 0px 50px 150px; */
  max-width: 100vw;
}

.card-table-head{
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
}

.card-table-row{
  border-radius: 0.375rem;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
  padding: 0.40rem;
}

.list-responsive {
  padding: 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.card-table-row:hover{
  background-color:rgb(49, 130, 206, 0.3);
}

.payment-status-pending {
  border-radius: 50px !important;
  color: #FFAB00 !important;
  background-color: #ffeecc !important;
  border-color: #ffeecc !important;
  box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%) !important;
}

.payment-status-pending:hover{
  border-radius: 50px !important;
  background-color: #FFAB00 !important;
  border-color: #FFAB00 !important;
  color: white !important;
  box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%) !important;
}

.payment-status-approved {
  border-radius: 50px !important;
  color: #36B37E !important;
  background-color: #d7f0e5 !important;
  border-color: #d7f0e5 !important;
  box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%) !important;
}
.payment-status-approved:hover {
  border-radius: 50px !important;
  color: white !important;
  background-color: #36B37E !important;
  border-color: #36B37E !important;
  box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%) !important;
}

.payment-status-denied {
  border-radius: 50px !important;
  color: #FF5630 !important;
  background-color: #ffddd6 !important;
  border-color: #ffddd6 !important;
  box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%) !important;
}
.payment-status-denied:hover {
  border-radius: 50px !important;
  color: white !important;
  background-color: #FF5630 !important;
  border-color: #FF5630 !important;
  box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%) !important;
}

.payment-status-refunded {
  border-radius: 50px !important;
  color: #fff !important;
  background-color: #0c0c0c40 !important;
  border-color: #0c0c0c40 !important;
  box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%) !important;
}
.payment-status-refunded:hover {
  border-radius: 50px !important;
  color: white !important;
  background-color: #0c0c0c70 !important;
  border-color: #0c0c0c70 !important;
  box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%) !important;
}

.rs-pagination-btn > a{
  background-color: #fff !important;
  border: 1px solid #dee2e6 !important;

  color: #007bff !important;
}

.rs-pagination-btn-disabled{
  background-color: rgba(0,0,0,0.2) !important;
}

.rs-pagination-btn-active > a{
  background-color:#3498ff !important;
  color: white !important; 
}

.rs-sidenav{ 
  transition: all 0s ease-in-out 0s !important;
}

.photoUploader{
  width: 100%;
  height: 180px;
  border-color: #ccc;
  border-style: dashed;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor:pointer;
  margin: 50px 15px !important;
}

.photoUploader > div > p {
  padding-top: 5%;
  font-size: 20px;
}

.rs-drawer-body{
  overflow-x: hidden !important;
  /* width: 86% !important; */
  max-height: 80vh !important;
}

.rs-message-error {
  /* background-color: #f5c6cb !important;
  color: #721c24 !important;  */
}

.svg-image-recovery-passwd{
  top: 20vh;
  position: absolute;
}


.checkout-header{
  padding-top: 20px;
}

.shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgb(0 0 0 / 6%);
}

.shadow-md-selected {
  box-shadow: 0 4px 6px -1px #349aff57, 0 2px 4px -1px rgb(0 0 0 / 6%);
}

.checkout-container{
  margin: 0 150px !important;
}

.payment-method-option{
  border-radius: 3px;
  background: white;
  padding: 25px 15px;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;

  display: flex;
  justify-content: center;
  align-items: center;
}

.payment-method-selected{
  border: 1px solid #349aff57;
  color: #3498ff;

  transition: border .5s;

}

.shipping-method-option{
  border-radius: 3px;
  background: white;
  padding: 25px 15px;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;

  display: flex;
  justify-content: center;
  align-items: center;

  /* border: 1px solid #27272757; */
  /* color: #262626; */

}

.shipping-method-option > .rs-radio-checker > label > .rs-radio-wrapper {
  top: 20px !important;
}

.shipping-shadow-md-selected {
  box-shadow: 0 4px 6px -1px #349aff57, 0 2px 4px -1px rgb(0 0 0 / 6%);
}

.shipping-logo {
  width: 150%;
}

.video-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  /* padding-left: 50px; */
  /* padding-right: 10px; */
  width: 100%;
}


.video-thumb-card {
  /* padding: 10px; */
  margin-right: 10px;
  flex: 1 1;
  /* max-width: 300px; */
  box-sizing: border-box;
}

@media (min-width: 768px) {
  .video-thumb-card {
      /* padding: 20px; */
  }
}


.shipping-method-selected{
  border: 1px solid #349aff57;
  color: #3498ff;

  transition: border .5s;

}


#payment-grid > .rs-flex-box-grid-item-3 {
  width: unset !important;
}

.only-mobile {
  display: none !important;
}

.card-credit-brands > .rs-panel-body {
  padding: 1px 10px !important;

  display: flex;
  justify-content: center;
  align-items: center;
}

.credit-card-title {
  margin-bottom: 6px;
  padding-left: 3px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

@media (min-width: 1790px) {
  .card-checkout{
    margin-right: 25px
  }
  .checkout-container{
    margin: 0 450px !important;
  }
  .checkout-header{
    padding: 20px 290px 25px 450px;
  }

  /* .payment-method-option{
    width: 250px;
  } */
}

.img-nothing{
  width: 80%;
}

.expanded{
  width: 290px !important;
}

.table-links{
  padding:"10px 15px";
}

.divider{
  width: 100%;
  border-top: 1px solid #0c0c0c20;
  padding-top: 10px;
  padding-bottom: 10px;
}

.home-section {
  padding-left: 50px;
  margin-top: 30px;
}

.continue-watching{
  width: 460px;
  max-width: 460px;
}

.continue-watching-container{
  display: flex;
  flex-direction: row;
}

.continue-watching-action{
  padding-left: 25px;
}

.section-swiper-container {
  margin-top: 15px;
}

.rs-footer{
  margin-top: 50px;
  height: 150px;
}

.footer-logo{
  margin-left: 40px;
}

.continue-bar-container{
  display: flex;
  align-items: flex-end;
}
.continue-bar-container > .bar{

  bottom: unset !important;
}

.back-button-reels{
  cursor: pointer !important;
  margin-top: 40px;
  margin-left: 25%;
  font-weight: bolder;
}

@media (max-width: 1024px) {

  .back-button-reels{
    margin: 20px;
    font-weight: bolder;
  }
  


  .rs-footer{
    margin-top: 90px;
  }

  .footer-logo{
    margin-left: 10px;
    
  }
  .course-title{
    text-shadow: 1px 2px 20px black;
  }

  #swiper-cover-container > div > div > div.swiper-slide{
    padding-left: 20px !important;
  }
  #story-swiper-container > div > div > div.swiper-slide{
    margin-left: 20px !important;
  }
  .continue-watching-container{
    display: flex;
    flex-direction: column;
    width: 100% !important;
    max-width: unset !important;
  }

  .continue-watching{
    width: 95%;
    max-width: unset !important;
  }

  .timeline-row{
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
  }

  .timeline-preview{
    margin: 0;
  }

  .thumbnail-preview-mini{
    width: 240px;
    height: 110px
  }

  .continue-watching-action{
    padding-left: 0;
    margin-top: 25px;
  }

  .section-swiper-container {
    margin-top: 5px;
  }

  .video-container{
    flex-direction: column;
  }

  .thumbnail-preview{
    margin-top: 15px;
    width: 100% !important;
  }

  .home-section {
    padding-left: 30px;
    margin-top: 30px;
  }

  .rs-nav-default {
    /* padding-top: unset !important;
    padding-bottom: unset !important; */
  }

  .shipping-logo {
    width: 100px;
  }
  .table-coproduction {
    width: calc(100% + 40rem) !important;
  }
  .productSectionTitle{
    margin-top: 25px;
  }
  .productSectionDescription{
    text-align: justify;
  }
  .paymentMethodSpaceing{
    margin-bottom: 10px;
  }
  .table-links{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
  .table-links > div {
    padding-bottom: 10px;
  }
  .input-clipboard{
    margin-right: 2%;
  }
  .col-flex-row{
    display: flex;
    flex-direction: row;
  }
  .expanded{
    width: unset !important;
  }

  .mobile-footer-item{
    margin-bottom: 25px !important;
  }

  .mobile-direction-column{
    flex-direction: column !important;
  }

  .mobile-fix-row-spacing{
    margin-top: 20px !important;
  }

  .mobile-text-justify{
    margin-top: 25px;
    text-align: justify !important;
  }

  .rs-nav-default{
    /* background: #3498ff !important; */
    /* padding-bottom: 10px;
    padding-top: 10px; */
  }

  .menu-icon-open{
    color: white;
  }

  .moneyIcon{
    margin-right: 20px;
    margin-left: 10px;
  }

  .rs-container{
    min-height: unset;
  }

  .blue-panel{
    height: 240px !important;
  }

  .text-white-center{
    /* margin-top: 95px; */
    font-size: 30px;
  }

  .img-nothing{
    width: 100%;
  }

  .card-saved-container{
    max-width: unset !important;
  }

  .credit-card-last4 {
    font-family: "PT Mono", Helvetica, sans-serif;
    font-size: 12px;
  }
  
  .credit-card-last4:before {
    content: "**** **** ";
    color: #4f4d4d;
    font-size: 12px;
  
    position: -webkit-sticky;
  
    position: sticky;
    top: 35px;
  }  

  .userAvatarContainer{
    margin-right: 3% !important;
  }

  .mobile-w-full{
    width: 100%;
  }

  .mobile-overflow-auto{
    overflow-x: auto;
  }

  .mobile-w-fit{
    width: -webkit-max-content;
    width: max-content;
  }

  .rs-drawer-body{
    height: unset !important;
  }

  .credit-card-title {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .credit-card-title > div {
    margin-bottom: 15px;
  }


  .checkout-container > .rs-row{
    display: flex;
    flex-direction: column-reverse;
  }

  .formHeader{
    margin-top: 25px;
  }
  .notFoundSvg{
    width: 100%;
    margin-top: 20%;
  }
  .notFoundText{
    text-align: center;
  }
  .only-mobile {
    display: block !important;
  }
  .only-desk {
    display: none !important;
  }

  .align-xs-center{
    text-align: center;
  }

  .checkout-header {
    padding: 20px 25px 25px 15px;
  }

  .checkout-container {
    margin: 0 20px !important;
  }
  

  .welcome-message{
    margin-left: unset;
    align-items: center;
    width: 100%;
  }

  .welcome{
    font-size: 1.3em;
  }

  .svg-model{
    display: none;
  }

  .blue-panel{
    height: 50vh;
    position: relative !important;
  }

  .logo-container {
    text-align: left;
    margin: 2em 2em 2em 2em;
  }

  .register-top{
    display: none;
  }

  /* .login-container{
    margin: 3em;
    width: 80%;
    padding-top: unset;
  } */

  .register-container{
    margin: 3em;
    width: 80%;
  }

  .login-title{
    display: none;
  }

  .content {
    padding: 50px 15px 15px;
    width: 100%;
  }

  .bigCard{
    min-height: unset;
  }

  .rs-modal{
    width: 100% !important;
    margin-top: 0 !important;
  }

  .rs-modal-wrapper{
    margin-top: 0 !important;
  }

  .rs-modal-content{
    border-radius: 0 !important;
    height: 100vh !important;
  }

  

  .rs-modal-body{
    margin-top: 20px;
    max-height: 85% !important;
    height: 85% !important;
  }

  .rs-drawer{
    width: 100% !important;
  }

  .rs-drawer-body{
    width: 90% !important;
    margin: 15px 22px !important;
  }

  .svg-image-recovery-passwd{
    top: 10vh;
    position: absolute;
  }

}




/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.rs-notification-description > p{
  color: black !important;
}

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
  60% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
  60% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}


.whatsappButton {
  position: fixed;
  z-index: 99999;
  right: 15px;
  -webkit-animation: bounce 2s;
          animation: bounce 2s; /* Animando indefinidamente */
}

.whatsappButton-low {
  bottom: 15px;
}

.whatsappButton-high {
  bottom: 90px;
}

/* animation */


.animation-ctn{
  text-align:center;
  margin-top:5em;
}

	@-webkit-keyframes checkmark {
    0% {
        stroke-dashoffset: 100px
    }

    100% {
        stroke-dashoffset: 200px
    }
}

@keyframes checkmark {
    0% {
        stroke-dashoffset: 100px
    }

    100% {
        stroke-dashoffset: 0px
    }
}

@-webkit-keyframes checkmark-circle {
    0% {
        stroke-dashoffset: 480px
    }

    100% {
        stroke-dashoffset: 960px;
      
    }
}

@keyframes checkmark-circle {
    0% {
        stroke-dashoffset: 480px 
    }

    100% {
        stroke-dashoffset: 960px
    }
}

@-webkit-keyframes colored-circle { 
    0% {
        opacity:0
    }

    100% {
        opacity:100
    }
}

@keyframes colored-circle { 
    0% {
        opacity:0
    }

    100% {
        opacity:100
    }
}

/* other styles */
/* .svg svg {
    display: none
}
 */
.inlinesvg .svg svg {
    display: inline
}

/* .svg img {
    display: none
} */

.icon--order-success svg polyline {
    -webkit-animation: checkmark 0.25s ease-in-out 0.7s backwards;
    animation: checkmark 0.25s ease-in-out 0.7s backwards
}

.icon--order-success svg circle {
    -webkit-animation: checkmark-circle 0.6s ease-in-out backwards;
    animation: checkmark-circle 0.6s ease-in-out backwards;
}
.icon--order-success svg circle#colored {
    -webkit-animation: colored-circle 0.6s ease-in-out 0.7s backwards;
    animation: colored-circle 0.6s ease-in-out 0.7s backwards;
} 

/* end animation */



#swiper-cover-container > div > div > div.swiper-slide{
  width: auto !important;
}

#story-swiper-container > div > div > div.swiper-slide {
  width: auto !important;
}


#reels-container > div.swiper > div.swiper-wrapper{
  height: 100vh;
}
.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ripple div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
            animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
}

@-webkit-keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }

    4.9% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }

    5% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }

    4.9% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }

    5% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}
#reels-container > .swiper {
    position: fixed !important;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 99999;
}
  
.course-cover {
    /* background-image: url('../../assets/img/people-2557396_1920.jpg'); */
    /* background-size: cover;
    width: 100vw;
    height: 450px; */
}

.container {
    position: relative;
    width: 100%;
    height: 50vh;
    /* background-image: url('../../assets/img/people-2557396_1920.jpg'); Adicione o caminho para a sua imagem de fundo */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.overlay {
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 60%; /* Ajuste a altura conforme necessário */
    background-image: linear-gradient(0deg,var(--background) 5%,transparent)
}

.cover-content{
    width: 100%;
    bottom: 15%;
    /* left: 5%; */
    padding: 0 5%;
    position: absolute;
}

.course-cta-button{
    width: auto;
    height: 40px;
}

.course-metadata{
    font-size: 0.8rem;
    color: #ffffffab;
}



.ledContainer {
	/* position: absolute; */
	/* top: 50%; */
	/* left: 50%; */
	/* transform: translate(-50%, -50%); */
	color: #fff;
	/* padding: 1.5rem 3rem; */
	display: flex;
    align-items: center;
	width: 380px;
	justify-content: flex-start;
}

.recordLed {
	width: 10px;
	height: 10px;
	background-color: red;
	border-radius: 50%;
}

.liveText {
	font-size: 12px;
}

.live {
    -webkit-animation: blinking 1s cubic-bezier(.5, 0, 1, 1) infinite alternate;
            animation: blinking 1s cubic-bezier(.5, 0, 1, 1) infinite alternate;  
}

@-webkit-keyframes blinking {  
    from { opacity: 1; }
    to { opacity: 0; }
}

@keyframes blinking {  
    from { opacity: 1; }
    to { opacity: 0; }
}


.custom-timeline {
    margin-left: 20px;
}

.custom-timeline .rs-timeline-item-custom-dot .rs-icon {
    position: absolute;
    background: rgb(87, 87, 87);
    top: 0;
    left: -2px;
    border: 2px solid rgb(87, 87, 87);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 18px;
    padding-top: 9px;
    color: #999;
    margin-left: -13px;
}

.custom-timeline .rs-timeline-item-content {
    margin-left: 24px;
}

.rs-timeline-item-tail{
    background: rgb(87, 87, 87) !important;
}
.course-cover {
    /* background-image: url('../../assets/img/people-2557396_1920.jpg'); */
    /* background-size: cover;
    width: 100vw;
    height: 450px; */
}

.container {
    position: relative;
    width: 100%;
    height: 50vh;
    /* background-image: url('../../assets/img/people-2557396_1920.jpg'); Adicione o caminho para a sua imagem de fundo */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.overlay {
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 60%; /* Ajuste a altura conforme necessário */
    background-image: linear-gradient(0deg,var(--background) 5%,transparent)
}

.cover-content{
    width: 100%;
    bottom: 15%;
    /* left: 5%; */
    padding: 0 5%;
    position: absolute;
}

.course-cta-button{
    width: auto;
    height: 40px;
}

.course-metadata{
    font-size: 0.8rem;
    color: #ffffffab;
}



.ledContainer {
	/* position: absolute; */
	/* top: 50%; */
	/* left: 50%; */
	/* transform: translate(-50%, -50%); */
	color: #fff;
	/* padding: 1.5rem 3rem; */
	display: flex;
    align-items: center;
	width: 380px;
	justify-content: flex-start;
}

.recordLed {
	width: 10px;
	height: 10px;
	background-color: red;
	border-radius: 50%;
}

.liveText {
	font-size: 12px;
}

.live {
    -webkit-animation: blinking 1s cubic-bezier(.5, 0, 1, 1) infinite alternate;
            animation: blinking 1s cubic-bezier(.5, 0, 1, 1) infinite alternate;  
}

@-webkit-keyframes blinking {  
    from { opacity: 1; }
    to { opacity: 0; }
}

@keyframes blinking {  
    from { opacity: 1; }
    to { opacity: 0; }
}


.custom-timeline {
    margin-left: 20px;
}

.custom-timeline .rs-timeline-item-custom-dot .rs-icon {
    position: absolute;
    background: rgb(87, 87, 87);
    top: 0;
    left: -2px;
    border: 2px solid rgb(87, 87, 87);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 18px;
    padding-top: 9px;
    color: #999;
    margin-left: -13px;
}

.custom-timeline .rs-timeline-item-content {
    margin-left: 24px;
}

.rs-timeline-item-tail{
    background: rgb(87, 87, 87) !important;
}
