@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');


:root {
    --primary-color: red;
    --light-color: white;

    --primary-color-hover: rgb(207, 3, 3);
    --primary-color-hover-extra: rgb(189, 9, 9);
    --light-color-hover: white;


    --background: rgb(18, 18, 20);
    --font: #ffffff;
    --font-dark: #141414;
    --background-layer2: #28282c;


    --colors-grey-100: #E1E1E6;
    --colors-grey-200: #C4C4CC;
    --colors-grey-300: #8D8D99;
    --colors-grey-400: #7C7C8A;
    --colors-grey-500: #505059;
    --colors-grey-600: #323238;
    --colors-grey-700: #29292E;
    --colors-grey-800: #202024;
    --colors-grey-900: #121214;
    --colors-grey-950: #09090A;
    --colors-text-title: #E1E1E6;
    --colors-text-base: #C4C4CC;
    --colors-shape-primary: #202024;

}

body {
    background: var(--background);
    font-family: 'Montserrat', sans-serif !important;
}

p {
    color: var(--light-color)
}
span.text-light {
    color: var(--light-color)
}


h1, h2, h3, h4, h5, h6 {
    color: var(--light-color);
}

.subtitle{
    color: #ffffffab !important;
}

.rs-modal-content{
    background-color: var(--background-layer2);
}

.rs-nav-item > .rs-nav-item-content {
    color: var(--font) !important;
}

.rs-nav-item > .rs-nav-item-content:hover {
    background: unset !important;
}


.rs-sidenav-inverse{
    background-color: var(--background) !important;
}

.rs-nav-default {
    background-color: var(--background-layer2) !important;
    border-bottom: 1px solid rgb(18, 18, 20);
}

.rs-nav-item-content{
    font-weight: 600;
}

.rs-dropdown-item-content > a {
    color: var(--font-dark);
}

.menu-container{
    margin-left: 20px;
}

.video-card-tag{
    background-color: var(--colors-grey-600);
    color: var(--light-color);
    text-transform: uppercase;
    font-size: .6rem;
    font-weight: 800;
    cursor: pointer;
    transition: background 0.2s ease 0s, color 0.2s ease 0s;
}
.video-card-tag:hover{
    background-color: var(--colors-grey-500);
}


.swiper-button-next::after, .swiper-button-prev::after {
    /* content: none !important; */
}

.swiper-button-next,
.swiper-button-prev {
    color: var(--light-color); /* Altere para a cor que desejar */
    top: 50%;
    width: 27px;
    height: 44px;
    margin-top: -22px;
}

.video-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-left: 50px;
    padding-right: 10px;
    width: 100%;
}

.video-thumb-card {
    /* padding: 10px; */
    flex: 1;
    /* max-width: 300px; */
    box-sizing: border-box;
}

@media (min-width: 768px) {
    .video-thumb-card {
        /* padding: 20px; */
    }
}

.mega-slider{
    height: 100%;
}

.button-small-text{
    font-size: 0.6rem;;
}

.like-button{
    color: #ffffffab;
    border-color: #ffffffab;
}

.like-button:hover,
.like-button:not(.rs-btn-disabled):hover,
.like-button:focus,
.like-button:active,
.like-button:hover:active {
    background: #ffffff;
    color: var(--background);
    border-color: #ffffff;
}

.rs-dropdown-item-panel > p {
    color: black !important;
}

.card-modules {
    background: var(--colors-grey-700);    
    border-radius: 5px;
}

.breadcrumb-text{
    color: var(--colors-grey-400);
}

.lesson-selected {
    background: var(--primary-color-hover-extra);
}

.theme-table{
    color: white;
    font-weight: bold;
}

.plyr__control--overlaid{
    background: var(--primary-color) !important;
}

.plyr__control:hover {
    background: var(--primary-color-hover) !important;

}

.plyr__control[aria-expanded=true] {
    background: var(--primary-color-hover) !important;

}

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]:before{
    background-color: var(--primary-color) !important;
}

.table > .card-table-row{
    background-color: var(--background-layer2);
    color: var(--font);
}
.table > .card-table-row:hover{
    background-color: var(--background-layer2) !important;
    color: var(--font)
}

.module-item{
    background: var(--colors-grey-700);
}

.rs-panel-heading{
    color: #ffffffab !important;
    font-weight: bold;
    font-size: 0.87rem;
}

.play-button-circle{
    color: var(--light-color);
    cursor: pointer;
    text-shadow: 3px 3px 20px rgb(0 0 0 / 51%);
}

.thumbnail-preview-mini{
    background-color: var(--colors-grey-200);
}

.progress-bar-theme{
    background-color: var(--primary-color);
}

.thumbnail-preview-mini > svg {
    color: rgb(97, 97, 97, 0.8) !important;
}

.custom-timeline .rs-timeline-item-custom-dot .rs-icon, not(.timeline-checked-dot) {
    background: var(--colors-grey-700) !important;
    border-color: var(--colors-grey-700) !important;
}

.timeline-checked-dot {
    background-color: #15b215 !important;
    border-color: #15b215 !important;
}

.rs-message-container{
    background-color: var(--background-layer2);
    color: white;
    font-weight: 500;
}
.rs-message-icon-wrapper > .rs-icon-info {
    color: white !important;
}

.rs-btn-primary {
    /* stylelint-disable-next-line */
    color: #ffffff;
    background-color: var(--primary-color);
}
.rs-btn-primary.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: var(--primary-color);
}
.rs-btn-primary.rs-btn-loading:hover,
.rs-btn-primary.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-primary.rs-btn-loading:focus,
.rs-btn-primary.rs-btn-loading:active,
.rs-btn-primary.rs-btn-loading:hover:active {
    color: var(--light-color-hover);
    background-color: var(--primary-color-hover);
}
.rs-btn-primary:focus,
.rs-btn-primary.rs-btn-focus {
    color: #ffffff;
    background-color: var(--primary-color-hover);
}
.rs-btn-primary:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: var(--primary-color-hover);
}
.rs-btn-primary:hover {
    color: var(--light-color-hover);
}
.rs-btn-primary:not(.rs-btn-disabled):hover {
    background-color: var(--primary-color-hover);
}
.rs-btn-primary:not(.rs-btn-disabled):hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: var(--primary-color-hover);
}
.rs-btn-primary:active,
.rs-btn-primary.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-btn-primary {
    color: #ffffff;
    background-color: var(--primary-color-hover);
}
.rs-btn-primary:active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: var(--primary-color-hover);
}
.rs-btn-primary:active:hover,
.rs-btn-primary.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-primary:hover,
.rs-btn-primary:active:focus,
.rs-btn-primary.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-primary:focus,
.rs-btn-primary:active.focus,
.rs-btn-primary.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.focus {
    color: var(--light-color-hover);
    background-color: var(--primary-color-hover);
}
.rs-btn-primary:active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary:active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary:active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: var(--primary-color-hover-extra);
}
.rs-btn-primary:active,
.rs-btn-primary.rs-btn-active,
.open > .rs-dropdown-toggle.rs-btn-primary {
    background-image: none;
}
.rs-btn-primary.rs-btn-disabled:hover,
.rs-btn-primary.rs-btn-disabled:focus,
.rs-btn-primary.rs-btn-disabled.focus {
    background: var(--primary-color-hover-extra);
}
.rs-btn-primary.rs-btn-loading:hover,
.rs-btn-primary.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-primary.rs-btn-loading:focus,
.rs-btn-primary.rs-btn-loading:active,
.rs-btn-primary.rs-btn-loading:hover:active {
    color: var(--light-color-hover);
    background-color: var(--primary-color-hover);
}

.rs-btn-primary,
.rs-btn-primary > .rs-icon {
    -webkit-transition: color 0.2s linear, background-color 0.3s linear;
    transition: color 0.2s linear, background-color 0.3s linear;
}

.rs-tag-red {
    background-color: #f443366b !important;
}
.rs-tag-orange {
    background-color: #ff98006b !important;
}
.rs-tag-yellow {
    background-color: #ffca286b !important;
}
.rs-tag-green {
    background-color: #7cff816b !important;
    }
.rs-tag-cyan {
    background-color: #00bcd46b !important;
}
.rs-tag-blue {
    background-color: #2196f36b !important;
}
.rs-tag-violet {
    background-color: #673ab76b !important;
}

.profile-avatar { 
    background: var(--primary-color-hover-extra) !important;
}

.watch-button {
    background-color: var(--primary-color) !important;
}

.watch-button{
    background-color: var(--primary-color);
}

.rs-icon{
    color: var(--primary-color);
}



/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio:0) {
    input[type='range'] {
        overflow: hidden;
        -webkit-appearance: none;
        border-radius: 15px;
        background-color: var(--primary-color);
    }
    
    input[type='range']::-webkit-slider-runnable-track {
        height: 10px;
        /* -webkit-appearance: none; */
        color: var(--primary-color);
        margin-top: -1px;
    }
    
    input[type='range']::-webkit-slider-thumb {
        width: 10px;
        -webkit-appearance: none;
        height: 10px;
        cursor: ew-resize;
        background: var(--primary-color-hover-extra);
        box-shadow: -80px 0 0 80px var(--primary-color);
    }

}
/** FF*/
input[type="range"]::-moz-range-progress {
    background-color: var(--primary-color); 
}
input[type="range"]::-moz-range-track {  
    background-color: var(--primary-color);
}
/* IE*/
input[type="range"]::-ms-fill-lower {
    background-color: var(--primary-color); 
}
input[type="range"]::-ms-fill-upper {  
    background-color: var(--primary-color);
}